import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { colors, Navigator } from '@rumblefish/ui';
import { Layout } from '@rumblefish/ui/AlgeaTheme';
import NextLink from 'next/link';
import React from 'react';
import type { NotFoundProps } from './NotFound.types';

const PREFIX = 'NotFound';

const classes = {
  navlink: `${PREFIX}-navlink`,
  button: `${PREFIX}-button`,
  errorH1: `${PREFIX}-errorH1`,
  zero: `${PREFIX}-zero`,
  allignCenter: `${PREFIX}-allignCenter`,
};

const StyledNotFoundWrapper = styled('div')({
  minHeight: '70vh',

  [`& .${classes.button}`]: {
    textDecoration: 'none',
    marginBottom: '40px',
    width: '180px',
    padding: '1rem',
    margin: '50px',
  },

  [`& .${classes.errorH1}`]: {
    marginBottom: '20px',
    fontWeight: 'bold',
    marginTop: '50px',
    color: colors.blue,
  },

  [`& .${classes.zero}`]: {
    color: colors.blue,
  },

  [`& .${classes.allignCenter}`]: {
    textAlign: 'center',
  },
});

export const NotFound: React.FC<NotFoundProps> = ({ blogPosts }) => {
  return (
    <Layout blogPosts={blogPosts}>
      <StyledNotFoundWrapper>
        <Navigator name="404" path="not-found" />
        <Grid
          my={10}
          container
          direction="row"
          justifyContent="center"
          alignItems="center">
          <Grid
            px={{ xs: 3, md: 18 }}
            item
            xs={12}
            className={classes.allignCenter}>
            <Card>
              <Typography className={classes.errorH1} variant="h1">
                404
              </Typography>
              <Typography px={2} variant="h6">
                THE PAGE YOU REQUESTED COULD NOT BE FOUND
              </Typography>
              <NextLink href="/">
                <Button variant="contained" className={classes.button}>
                  Back to home page
                </Button>
              </NextLink>
            </Card>
          </Grid>
        </Grid>
      </StyledNotFoundWrapper>
    </Layout>
  );
};

export default NotFound;

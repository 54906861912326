import type { GetStaticProps } from 'next';
import { fetchBlogPosts } from '../utils/api/fetchStrapiData';
import { NotFound } from '../views/not-found';

export const getStaticProps: GetStaticProps = async () => {
  const data = await fetchBlogPosts();

  return {
    props: { blogPosts: data },
  };
};

export default NotFound;
